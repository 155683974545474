import { Link } from "gatsby"
import React, { useEffect, useState } from "react"

import ConfirmationImg from "../../../images/Confirmation.png"
import * as styles from "./Confirmation.module.scss"

const Confirmation = (props: any) => {
  const params = props.location.search
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    const main = document.querySelector("main") as unknown as HTMLElement
    main.setAttribute("style", "height: 100%;")

    setMounted(true)
  }, [])

  return (
    <div className={styles.confirmation}>
      <img src={ConfirmationImg} alt="Confirmation" />
      {mounted && (
        <>
          <div className={styles.confirmationInner}>
            <>
              <h1>
                {params === "" ? "You're all set!" : "Thanks for reaching us!"}
              </h1>
              <h2>Our team will get in touch with you shortly.</h2>
            </>
          </div>

          <Link to="/">Back to Home</Link>
        </>
      )}
    </div>
  )
}

export default Confirmation
